import { css } from 'styled-components'

import type { BoxPropValue } from '@fortum/elemental-ui'
import { generateBoxPropStyles } from '@fortum/elemental-ui'

import type { CosColor, colors } from '../utils/colors'

export const truncateMultiLineText = (lines: BoxPropValue) => css`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  ${generateBoxPropStyles('-webkit-line-clamp', lines)}
`

export const truncate = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

type ColorStop = {
  color: keyof typeof colors & CosColor
  stop?: string
}

type GradientParams = {
  direction?: string
  colorStops: ColorStop[]
}

export const gradient = ({ direction = 'to right', colorStops }: GradientParams) => {
  if (!colorStops || colorStops.length < 2) {
    throw new Error('Must provide at least two color stops for gradient.')
  }

  const gradientValues = colorStops.map(({ color, stop }, i) => {
    const position = stop || `${(100 / (colorStops.length - 1)) * i}%`
    return `${color} ${position}`
  })

  return css`
    background: linear-gradient(${direction}, ${gradientValues.join(', ')});
  `
}

/**
 * Custom focus outline style based on Elemental.
 */
export const focusOutline = css`
  outline-offset: 0;
  box-shadow: 0 0 0 4px ${({ theme }) => theme.tokens.focus.primary};
  outline: 2px solid ${({ theme }) => theme.tokens.focus.secondary};
`
