import styled from 'styled-components'

import { InputField, spacing } from '@fortum/elemental-ui'

export const StyledInput = styled(InputField)`
  input,
  :enabled:focus {
    border-radius: ${spacing.xxxxs};
  }
`
