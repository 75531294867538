import type { PropsWithChildren } from 'react'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { Box, Section, colors, spacing } from '@fortum/elemental-ui'

export type StickyFooterProps = PropsWithChildren & {
  /**
   * Defines if the footer should appear from the bottom.
   */
  isShown: boolean
}

/**
 * Footer sticky component that is using fixed positioning
 * and appearing from the bottom of the page to present persistent data.
 */
export const StickyFooter = forwardRef<HTMLDivElement, StickyFooterProps>((props, ref) => {
  const { isShown, children } = props
  return (
    <Wrapper
      position="fixed"
      width="100%"
      bottom="0"
      left="0"
      backgroundColor={colors.snowWhite}
      $isShown={isShown}
      boxRef={ref}
      data-testid="sticky-footer"
    >
      <Section paddingVertical={spacing.xxs}>{children}</Section>
    </Wrapper>
  )
})

StickyFooter.displayName = 'StickyFooter'

const Wrapper = styled(Box)<{ $isShown: boolean }>`
  transition:
    transform 350ms,
    box-shadow 350ms;
  z-index: 10;
  box-shadow: ${(props) => (props.$isShown ? '0px 0px 27px 8px rgba(0, 0, 0, 0.10)' : 'none')};
  transform: ${(props) => (props.$isShown ? 'none' : 'translateY(125%)')};
`
