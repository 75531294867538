'use client'

import type { ContractContent } from '@/shared/components/Cards/ContractCard'
import { getProductJsonLd } from '@/shared/utils/jsonLd'

export const ProductJsonLd = (contract: ContractContent) => {
  const jsonLd = getProductJsonLd(contract)

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLd) }}
    />
  )
}
