'use client'

import { useTranslations } from 'next-intl'
import { useEffect, useState } from 'react'
import { FormProvider } from 'react-hook-form'

import {
  Box,
  ButtonPill,
  Hidden,
  IconArrowRight,
  px2rem,
  shadows,
  spacing,
} from '@fortum/elemental-ui'

import { AdditionalInfoForm } from '@/shared/components/AdditionalInfoForm'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useAddressAdditionalForm } from '@/shared/hooks/useAddressAdditionalForm'
import { useTheme } from '@/shared/hooks/useTheme'
import { Link, useRouter } from '@/shared/navigation'
import { useGlobalStore } from '@/shared/store/provider'
import { stepEvent } from '@/shared/utils/sendGTMEvent'

import { Consumption } from './Consumption'
import { HighConsumptionInfo } from './HighConsumptionInfo'
import { HighConsumptionModal } from './HighConsumptionModal'
import { HousingSelect } from './HousingSelect'
import { Input } from './Input'
import type { QuickSearchForm, QuickSearchProps } from './types'
import { ENTERPRISE_USAGE_LIST, QUICK_SEARCH_CONFIG } from './utils'

export const QuickSearchEnterprise: React.FC<QuickSearchProps> = ({
  layout = 'responsive',
  primaryLink,
}) => {
  const [isConsumptionInputManual, setIsConsumptionInputManual] = useState(false)
  const l = useTranslations('quickSearchForm.labels')
  const router = useRouter()
  const { colors } = useTheme()
  const flexDirection = layout === 'vertical' ? 'column' : { default: 'column', l: 'row' }

  const {
    housingType,
    usageOption,
    estimatedConsumption,
    deliveryArea,
    _isLoading,
    shouldDisableContractSelect,
    setUsageOption,
    setShouldDisableContractSelect,
    resetHousingInfo,
  } = useGlobalStore((state) => state.housingInfo)

  const {
    form,
    onChange,
    isAdditionalFormVisible,
    isPriceAreaError,
    shouldShowModal,
    handleModalClose,
  } = useAddressAdditionalForm<QuickSearchForm>()

  const primaryLinkButton = () => {
    if (primaryLink) {
      return (
        <ButtonPill
          tag={Link}
          onClick={(event) => {
            event.preventDefault()
            router.push(getLinkEntryUrl(primaryLink))
            stepEvent('find_contract', {
              usage_option: usageOption,
              est_consumption: estimatedConsumption,
            })
          }}
          alignSelf="stretch"
          data-testid="find-contracts-button"
          rightIcon={<IconArrowRight size={24} />}
          disabled={shouldDisableContractSelect}
        >
          {primaryLink.label}
        </ButtonPill>
      )
    }
    return null
  }

  //Trigger high consumption modal
  useEffect(() => {
    if (usageOption === 'large') {
      setShouldDisableContractSelect(true)
    } else {
      setShouldDisableContractSelect(false)
    }
  }, [usageOption])

  //Update housing option select on change in consumption value
  useEffect(() => {
    if (
      estimatedConsumption &&
      estimatedConsumption < ENTERPRISE_USAGE_LIST.small.maxUsage &&
      usageOption !== 'small'
    ) {
      setUsageOption('small')
    }
    if (
      estimatedConsumption &&
      estimatedConsumption >= ENTERPRISE_USAGE_LIST.small.maxUsage &&
      estimatedConsumption < ENTERPRISE_USAGE_LIST.medium.maxUsage &&
      usageOption !== 'medium'
    ) {
      setUsageOption('medium')
    }
  }, [estimatedConsumption, usageOption])

  //Reset state if coming from Private site
  useEffect(() => {
    if (housingType) {
      resetHousingInfo()
    }
  }, [housingType])

  return (
    <Box borderRadius={spacing.xxxs} backgroundColor={colors.backgroundPrimary}>
      <FormProvider {...form}>
        <form>
          <Box
            display="flex"
            flexDirection="column"
            gap={{ default: spacing.xxs, s: spacing.xs }}
            p={{ default: spacing.xxs, s: spacing.xs }}
            boxShadow={shadows.m}
            borderRadius={spacing.xxxs}
          >
            <Box
              display="flex"
              flexDirection={flexDirection}
              flexWrap="wrap"
              alignItems={
                layout === 'responsive' ? { default: 'stretch', l: 'flex-start' } : 'stretch'
              }
              gap={{ default: spacing.xxs, s: spacing.xs, l: spacing.xxs }}
            >
              <Input
                {...QUICK_SEARCH_CONFIG.postalCode}
                texts={{
                  topText: l('postalCode.topText'),
                  label: l('postalCode.label'),
                  errorMessage: l('postalCode.errorMessage'),
                }}
                onChange={onChange}
                isLoading={_isLoading}
                isError={isPriceAreaError}
                isSuccess={Boolean(deliveryArea)}
              />
              {isAdditionalFormVisible && (
                <AdditionalInfoForm
                  onChange={onChange}
                  streetAddress={QUICK_SEARCH_CONFIG.streetAddress}
                  city={QUICK_SEARCH_CONFIG.city}
                />
              )}
              <HousingSelect
                {...QUICK_SEARCH_CONFIG.usageOption}
                handleSelect={onChange}
                isEnterprise={true}
                texts={{
                  topText: l('usageOption.topText'),
                  label: l('usageOption.label'),
                  errorMessage: l('usageOption.errorMessage'),
                }}
              />
              <Hidden
                below="l"
                alignSelf={
                  //Center the link button when there are errors for neighboring fields resent, so it still aligns correctly
                  isPriceAreaError ||
                  Boolean(form.formState.errors.postalCode) ||
                  Boolean(form.formState.errors.usageOption)
                    ? 'center'
                    : 'flex-end'
                }
              >
                {primaryLinkButton()}
              </Hidden>
            </Box>
            {(estimatedConsumption || primaryLink || isConsumptionInputManual) && (
              <Box
                display="flex"
                flexDirection={flexDirection}
                gap={{ default: spacing.xxs, s: spacing.xs, l: spacing.xxs }}
                minHeight={{ default: 'auto', l: px2rem(99) }}
              >
                {usageOption &&
                  (usageOption === 'large' ? (
                    <HighConsumptionInfo />
                  ) : (
                    <Consumption
                      isConsumptionInputManual={isConsumptionInputManual}
                      setIsConsumptionInputManual={setIsConsumptionInputManual}
                      onChange={onChange}
                    />
                  ))}
              </Box>
            )}
            <Hidden above="l">
              <Box display="flex" flexDirection="column">
                {primaryLinkButton()}
              </Box>
            </Hidden>
          </Box>
        </form>
      </FormProvider>
      <HighConsumptionModal isOpened={shouldShowModal} handleClose={handleModalClose} />
    </Box>
  )
}
