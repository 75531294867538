import { BLOCKS } from '@contentful/rich-text-types'
import { useTranslations } from 'next-intl'

import { Box, ButtonPill, Modal, spacing } from '@fortum/elemental-ui'

import type { RichTextDocument } from '@/shared/components/RichText'
import { RichText, renderRichTextHeading } from '@/shared/components/RichText'

export type HourlyMeasurementModalProps = {
  /**
   * Callback triggered on confirm button click.
   */
  onConfirm: VoidFunction
  /**
   * Is modal opened.
   */
  isOpened: boolean
  /**
   * Callback triggered on cancel button click.
   */
  onCancel: VoidFunction
  /**
   * Content of the modal presented below title and description taken from crowdin.
   */
  content?: RichTextDocument | null
}

/**
 * Confirmation modal with information related to hourly measurement. Feature dedicated mainly for Sweden.
 */
export const HourlyMeasurementModal = ({
  onConfirm,
  isOpened,
  onCancel,
  content,
}: HourlyMeasurementModalProps) => {
  const t = useTranslations('contractCard.hourlyMeasurementModal')

  return (
    <Modal alignContent="left" opened={isOpened} onClose={onCancel}>
      <Box display="flex" flexDirection="column" gap={spacing.xxs}>
        {content && (
          <RichText
            document={content}
            customOptions={{
              renderNode: {
                // Style heading levels 2 as levels 4, aligned to center as top heading of the modal
                [BLOCKS.HEADING_2]: renderRichTextHeading(2, 4, { textAlign: 'center' }),
              },
            }}
          />
        )}
      </Box>
      <Box
        mt={spacing.xxl}
        display="flex"
        flexDirection={{ default: 'column', l: 'row' }}
        justifyContent="center"
        alignItems="center"
        gap={spacing.xs}
      >
        <ButtonPill data-testid="confirm-hourly-measurement-button" onClick={onConfirm}>
          {t('confirmButton')}
        </ButtonPill>
        <ButtonPill
          data-testid="cancel-hourly-measurement-button"
          status="secondary"
          onClick={onCancel}
        >
          {t('cancelButton')}
        </ButtonPill>
      </Box>
    </Modal>
  )
}
