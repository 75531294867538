import { useTranslations } from 'next-intl'
import type { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'

import {
  Box,
  ButtonPill,
  ContentText,
  IconEdit,
  fontWeights,
  spacing,
  styles,
} from '@fortum/elemental-ui'

import { Icon } from '@/shared/components/Icon'
import { useTheme } from '@/shared/hooks/useTheme'
import { useGlobalStore } from '@/shared/store/provider'

import { Input } from '../Input'
import type { QuickSearchForm } from '../types'
import { QUICK_SEARCH_CONFIG } from '../utils'

type ConsumptionProps = {
  isConsumptionInputManual: boolean

  onChange: (_name: keyof QuickSearchForm, value: string) => void
  setIsConsumptionInputManual: Dispatch<SetStateAction<boolean>>
}

export const Consumption = ({
  isConsumptionInputManual,
  onChange,
  setIsConsumptionInputManual,
}: ConsumptionProps) => {
  const { colors } = useTheme()
  const t = useTranslations('quickSearchForm')
  const l = useTranslations('quickSearchForm.labels.estimatedConsumption')

  const { estimatedConsumption } = useGlobalStore((state) => state.housingInfo)

  if (isConsumptionInputManual) {
    return (
      <Input
        {...QUICK_SEARCH_CONFIG.estimatedConsumption}
        texts={{
          topText: l('topText'),
          label: l('label'),
          errorMessage: l('errorMessage'),
        }}
        onChange={onChange}
      />
    )
  }

  return (
    <Box display="flex" flexDirection="column" gap={spacing.xxxs} flex="1">
      <ContentText color={colors.textSecondary}>{l('topText')}</ContentText>
      <Box display="flex" alignItems="baseline" gap={spacing.xxxs}>
        <ConsumptionValueText
          color={colors.textPositive}
          fontWeight={fontWeights.medium}
          data-testid="quick-search-form-consumption-value"
        >
          {estimatedConsumption}
        </ConsumptionValueText>
        <ContentText>{t('consumptionUnit')}</ContentText>
        <ButtonPill
          size="s"
          status="plain"
          fontSize="1rem"
          rightIcon={<Icon icon={IconEdit} />}
          onClick={() => setIsConsumptionInputManual(true)}
          data-testid="quick-search-enable-consumption-edit"
        >
          {t('consumptionChangeButtonLabel')}
        </ButtonPill>
      </Box>
    </Box>
  )
}

const ConsumptionValueText = styled(ContentText)`
  font-size: 2.25rem;

  ${styles.breakpointMax.l} {
    font-size: 1.8125rem;
  }

  ${styles.breakpointMax.s} {
    font-size: 1.4375rem;
  }
`
