'use client'

import type { KeyboardEventHandler, ReactNode } from 'react'

import type { BoxPropValue } from '@fortum/elemental-ui'
import { Box, shadows, spacing } from '@fortum/elemental-ui'

import type { BadgeEntry } from '@/shared/contentful/types'
import { useTheme } from '@/shared/hooks/useTheme'

import { CardBadge } from '../Cards/shared/CardBadge'
import type { IconId } from '../Icon'

export type CardContainerProps = {
  /**
   * Content of the card as ReactNode.
   */
  children: ReactNode

  /**
   * Width of the card.
   */
  width: BoxPropValue

  /**
   * minimum Width of the card.
   */
  minWidth?: BoxPropValue

  /**
   * Badge configuration eg.:
   */
  cardBadge?: Partial<BadgeEntry> | null

  /**
   * Set custom color
   */
  colors?: ColorProps<never, 'textPositive' | 'backgroundPositive'>

  /**
   * Optional top border height.
   */
  topBorderHeight?: string

  /**
   * Box shadow size.
   */
  boxShadowSize?: keyof typeof shadows | null
  /**
   * Attribute data-testid for testing purposes.
   */
  testId?: string
  outline?: string
  tabIndex?: number
  onKeyDown?: KeyboardEventHandler<HTMLDivElement> & KeyboardEventHandler<HTMLElement>
  /**
   * Dims and disables all interactions for contract page, should be used together with disabling purchase button
   */
  isDisabled?: boolean
}

/**
 * Simple container with shadow for ContractCard component.
 */
export const CardContainer = ({
  children,
  width,
  minWidth,
  cardBadge,
  colors,
  topBorderHeight,
  boxShadowSize = 's',
  testId = 'card-container',
  outline,
  tabIndex,
  onKeyDown,
  isDisabled,
}: CardContainerProps) => {
  const theme = useTheme()

  return (
    <Box
      width={width}
      minWidth={minWidth}
      background={theme.colors.backgroundPrimary}
      boxShadow={boxShadowSize !== null ? shadows[boxShadowSize] : undefined}
      borderRadius={spacing.xxxs}
      data-testid={testId}
      style={{ outline }}
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      opacity={isDisabled ? 0.5 : undefined}
      cursor={isDisabled ? 'not-allowed' : undefined}
    >
      {cardBadge && (
        <Box position="relative" display="flex" justifyContent="center">
          <CardBadge
            name={cardBadge.name || ''}
            title={cardBadge.title || ''}
            colors={{
              backgroundColor: colors?.textPositive || '',
              textColor: colors?.backgroundPositive || '',
            }}
            icon={cardBadge.icon as IconId}
            transform={
              topBorderHeight ? `translateY(calc(50% - ${topBorderHeight}))` : 'translateY(50%)'
            }
          />
        </Box>
      )}
      {children}
    </Box>
  )
}
