import { useTranslations } from 'next-intl'

import { Box, ContentText, spacing } from '@fortum/elemental-ui'

import { Icon } from '@/shared/components/Icon'
import { StyledLink } from '@/shared/components/StyledLink'
import { useTheme } from '@/shared/hooks/useTheme'

export const HighConsumptionInfo = () => {
  const { colors } = useTheme()
  const t = useTranslations('quickSearchForm')
  const t1 = useTranslations('quickSearchForm.highConsumptionLabel')

  const text = t1.rich('text', {
    a: (chunks) => (
      <StyledLink color={colors.textPrimary} size="xs" href={t('contactFormLink')}>
        {chunks}
      </StyledLink>
    ),
  })

  return (
    <Box
      display="flex"
      flexDirection="row"
      flex={1}
      alignItems="flex-start"
      backgroundColor={colors.backgroundPositive}
      p={spacing.xxxs}
      gap={spacing.xxxs}
      borderRadius="0.25rem"
      height="fit-content"
    >
      <Icon icon="IconFaqUi" size={24} color={colors.textPositive} />
      <Box display="flex" flexDirection="column">
        <ContentText size="xs" fontWeight="500" color={colors.textPrimary}>
          {t1('title')}
        </ContentText>

        <ContentText size="xs">{text}</ContentText>
      </Box>
    </Box>
  )
}
