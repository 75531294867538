'use client'

import { useTranslations } from 'next-intl'
import type React from 'react'
import type { Path, RegisterOptions } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { Box, ContentText, IconInfo, spacing } from '@fortum/elemental-ui'

import { StyledInput } from '@/open-web/components/QuickSearch/styled'
import { Icon } from '@/shared/components/Icon'
import { useTheme } from '@/shared/hooks/useTheme'

export type AdditionalInfoFormProps<T extends Record<string, unknown>> = {
  onChange: (name: Path<T>, value: string) => void
  streetAddress: { name: Path<T>; rules?: RegisterOptions<T> }
  city: { name: Path<T>; rules?: RegisterOptions<T> }
}

export const AdditionalInfoForm = <T extends Record<string, unknown>>({
  onChange,
  streetAddress,
  city,
}: AdditionalInfoFormProps<T>) => {
  const t = useTranslations('quickSearchForm')
  const { colors } = useTheme()

  const {
    field: { ref: streetNameRef, ...streetNameFieldProps },
    fieldState: streetNameFieldState,
  } = useController(streetAddress)
  const {
    field: { ref: cityRef, ...cityFieldProps },
    fieldState: cityFieldState,
  } = useController(city)

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexBasis="100%"
      order={{ default: 0, l: 1 }}
      gap={spacing.xxs}
      p={spacing.xxs}
      borderRadius="0.25rem"
      backgroundColor={colors.backgroundWarning}
      data-testid="additional-info-form"
    >
      <Box display="flex" gap={spacing.xxs} alignItems="flex-start">
        <Icon icon={IconInfo} />
        <ContentText flex="1">{t('additionalInfoDisclaimer')}</ContentText>
      </Box>
      <Box display="flex" flexDirection={{ default: 'column', l: 'row' }} gap={spacing.xxs}>
        <StyledInput
          {...streetNameFieldProps}
          value={streetNameFieldProps.value as string | undefined}
          label={t('labels.streetAddress.label')}
          errorMessage={t('labels.streetAddress.errorMessage')}
          inputRef={streetNameRef}
          error={Boolean(streetNameFieldState.error)}
          data-testid="additional-info-form-street-name"
          onChange={(e) => {
            onChange(streetNameFieldProps.name, e.target.value)
          }}
        />
        <StyledInput
          {...cityFieldProps}
          value={cityFieldProps.value as string | undefined}
          label={t('labels.city.label')}
          errorMessage={t('labels.city.errorMessage')}
          inputRef={cityRef}
          error={Boolean(cityFieldState.error)}
          data-testid="additional-info-form-city"
          onChange={(e) => {
            onChange(cityFieldProps.name, e.target.value)
          }}
        />
      </Box>
    </Box>
  )
}
