import { useTranslations } from 'next-intl'

import { Box, ButtonPill, ContentText, IconContact, Modal, spacing } from '@fortum/elemental-ui'

import { Icon } from '@/shared/components/Icon'
import { StyledHeading } from '@/shared/components/StyledHeading'
import { Link, useRouter } from '@/shared/navigation'
import { useGlobalStore } from '@/shared/store/provider'

type HighConsumptionModalProps = {
  isOpened: boolean
  handleClose: VoidFunction
}

export const HighConsumptionModal = ({ isOpened, handleClose }: HighConsumptionModalProps) => {
  const router = useRouter()
  const t = useTranslations('quickSearchForm')
  const t1 = useTranslations('quickSearchForm.highConsumptionModal')

  const { setUsageOption } = useGlobalStore((state) => state.housingInfo)

  return (
    <Modal opened={isOpened} onClose={handleClose} hideCloseButton={true}>
      <Box display="flex" flexDirection="column" alignItems="center" gap={spacing.xxs}>
        <Icon icon={IconContact} size={48} />
        <StyledHeading level={4}>High electricity consumption</StyledHeading>
        <Box display="flex" flexDirection="column" alignItems="center" gap={spacing.xxl}>
          <ContentText size="m" textAlign="center">
            {t1('text')}
          </ContentText>
          <Box
            display="flex"
            flexDirection={{ default: 'column', s: 'row' }}
            gap={spacing.xs}
            justifyContent="center"
          >
            <ButtonPill
              tag={Link}
              onClick={(event) => {
                event.preventDefault()
                setUsageOption(undefined)
                router.push(t('contactFormLink'))
              }}
            >
              {t1('contactButton')}
            </ButtonPill>
            <ButtonPill onClick={handleClose} status="secondary">
              {t1('backButton')}
            </ButtonPill>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
